<template>
  <b-row class="container-results-proposals m-0 mx-auto">
    <div class="container-loader-searcher loader-table-cards" v-if="loader_results">
        <b-spinner class="spinner-table-searcher"></b-spinner>
      </div>
    <b-col class="mb-2 col-12 p-0" v-if="proposals.length === 0 || ((Object.keys($route.query).length === 0) && (Object.keys($route.query).length === 0 && networks.length === 0))">
      <component :is="vue_horizontal" class="horizontal" :displacement="0.3" snap="center">
        <collection-card-network 
        v-for="(i, index) in network" :key="index"
        :data="i"
          :index="index + 1"
          @reload_service="reloadServiceCollectionsNetworks"
          :img="imgNetwork(i)"
          class="component-collection-network"
          />
      </component>
    </b-col>
    <b-col class="filters-fixed-nav mb-1 col-12" ref="filters_fixed">
      <div class="row max-width-1400 m-0 ">
        <div class="col-12 col-md-3 col-lg-2 p-0 mb-1 mb-md-0 d-flex justify-content-between border-right pr-1">
          <b-button 
            variant="button-filters" 
            pill 
            :class="`button-filters position-relative ${filters_used_number > 0 ? 'justify-content-between' : 'justify-content-center'}`" 
            @click="open_modal = true; update_modal = !update_modal;">
            <div>
              <b-icon icon="sliders"></b-icon> 
              {{ $t('creator.filters') }}
            </div>
            <div class="badge-number-filters-used" v-if="filters_used_number > 0">{{filters_used_number}}</div>
          </b-button>
          <b-button pill class="button-filters button-trash-icon" variant="button-filters button-trash-icon" @click="clearFilters(true)">
            <feather-icon icon="TrashIcon"></feather-icon>
          </b-button>
        </div>
        <component :is="vue_horizontal" responsive class="horizontal col-12 col-md-9 col-lg-10 d-flex align-items-center p-0 pl-1" :displacement="0.3" snap="center" v-if="loaded">
          <b-badge 
            variant="outline-secondary-light" 
            :class="`outline-secondary-light ${isActiveClass('networks', net)}`" 
            v-for="(net, index) in network" :key="index"
            @click="addNetworkFilter(net)"
          >
            {{nameNetworks(net)}}
          </b-badge>
        </component>
      </div>
    </b-col>
    <b-col class="d-flex flex-wrap min-height-500 p-0 w-100" v-if="proposals.length > 0">
      <div class="col-12 mb-2 p-0">
        <strong class="w-100 h3 d-block">{{(networks.length > 0) ? getNetworksCapitalize : $t('creator.allProposals')}} </strong>
        <span class="h5" v-if="$route.query.query">
          {{$t('creator.searchBy')}} "{{$route.query.query}}"
        </span>
      </div>
      <div class="w-100">
        <div class="grid-search-proposal w-100 position-relative">
          <div v-for="item in proposalsFiltered" :key="item.uuid">
            <card-category
              :proposal="item"
              @remove_fixed_nav="removeFixedNavFilters"
              :width="''"
            />
          </div>
        </div>
        <pagination-brandme 
          class="col-12 d-flex justify-content-center mt-2"
          :total_rows="proposals.length"
          :per_page="per_page"
          :current_page="current_page"
          @update_current_page="updateCurrentPage"
        ></pagination-brandme>
      </div>
    </b-col>
    <b-col class="empty-state-container-proposals" v-if="proposals.length === 0">
      <no-Results 
        :title="`${$t('creator.noResults')} ${$route.query.query ? $route.query.query : ''}`"
        :subtitle="$t('creator.textFilters')"
        :text_button="$t('creator.search')"
        :btn_action="clearFilters"
        :show_icon="false"
      />
    </b-col>
    <b-col class="col-12 p-0" v-if="role !== 'creator'">
      <card-casting-call
        :disabled_btn="false"
        :colorCard="'color-card-custom'"
        :btn_action="redirectCreateCasting"
        :textInfo="$t('creator.chooseChampion')"
        :subtitle="$t('creator.directRelation')"
        :textButton="avaible_plan ? $t('dashboard.create_casting_call') : $t('creator.upgrade_to_create_castings')"
        :img="getUrlCategory('home_casting_call')"
        :can_create_casting="avaible_plan"
      />
    </b-col>

    <filter-search-proposal 
      :open_modal="open_modal" 
      :key="update_modal"
      :filters_layout="filters_layout"
      @filter="filterAction"
    ></filter-search-proposal>
  </b-row>
</template>

<script>
import {
  BRow,
  BCol,
  BButton,
  BBadge,
  BSpinner,
} from 'bootstrap-vue';
import proposals_services from '@/services/proposals';
import { getUrlCategory } from '@/libs/utils/urls'
import { nameNetworks } from '@/libs/utils/formats'
import { getUserData } from '@/libs/utils/user'

export default {
  name: 'searchProposal',
  components: {
    BRow,
    BCol,
    BSpinner,
    BButton,
    BBadge,
    cardCategory: () => import('@/views/pages/proposals/cardCategory.vue'),
    collectionCardNetwork: () => import('@/views/components/proposals/collectionCardNetwork.vue'),
    noResults: () => import('@/views/pages/proposals/stepper/noResults.vue'),
    cardCastingCall: () => import('@/views/components/proposals/cardCastingCall.vue'),
    filterSearchProposal: () => import('@/views/components/proposals/filterSearchProposal.vue'),
    paginationBrandme: () => import('@/views/components/pagination/PaginationBrandme.vue')
  },
  data() {
    return {
      getUrlCategory,
      nameNetworks,
      avaible_plan: getUserData().current_plan.create_casting_call,
      proposals: [],
      collection_card_networks: [],
      network: ['tiktok', 'instagram','youtube','facebook','linkedin','twitch','twitter','spotify','pinterest','blog','snapchat'],
      current_page: 1,
      filters_used_number: 0,
      per_page: 12,
      networks: [],
      query: undefined,
      open_modal: false,
      update_modal: false,
      loader_results: false,
      filters_layout: [],
      filters: {},
      loaded: false,
      changFilters: false,
      role: '',
      filter_button_selected: {
        selected: false,
        type_button: '',
        list_button: [],
      },
      vue_horizontal: null,
    }
  },
  computed: {
    getNetworksCapitalize() {
      let nets = this.networks.slice();
      nets = nets.map((n) => nameNetworks(n));
      return nets.join(', ');
    },
    proposalsFiltered () {
      const items = this.proposals;
      return items.length > 0 ? items.slice((this.current_page - 1) * this.per_page, this.current_page * this.per_page) : 0;
    },
  },
  created() {
    const body = document.getElementsByTagName('body')[0];
    body.style.backgroundColor = "white";
    this.$root.$on('update_proposals', (selected_button) => {
      if (selected_button) this.filter_button_selected = selected_button;
      this.addFiltersFromQuery();
      this.getProposals();
      this.$root.$emit('update_color_button_selected', this.filter_button_selected);
    });
    this.addFiltersFromQuery();
    this.getProposals();
    setTimeout(() => {
      document.getElementsByClassName('filters-fixed-nav')[0].style.display = 'block'
    }, 500)
    const userData = getUserData();
  
    if (userData && userData.groups && userData.groups.length > 0) {
      this.role = userData.groups[0].name;
    }
  },
  async mounted() {
    this.vue_horizontal = (await import('vue-horizontal')).default;
  },
  destroyed() {
    const body = document.getElementsByTagName('body')[0];
    body.removeAttribute('style');
  },
  methods: {
    updateCurrentPage(new_current_page) {
      this.current_page = new_current_page;
    },
    redirectCreateCasting() {
      if (this.avaible_plan) this.$router.push({ name: 'steps_casting_call', params: {step: 'select-service'}})
      else this.$router.push({name: 'pages-account-setting', params: { section: 'membership'}})
    },
    imgNetwork(category) {
      return getUrlCategory(category)
    },
    addFiltersFromQuery() {
      const query = {};
      this.networks = [];
      if (Object.keys(this.$route.query).length > 0) {
        if (this.$route.query.network) {
          this.networks.push(this.$route.query.network);
          this.filters = query;
        } else if (this.$route.query.work_types) {
          if (Object.keys(this.filters).length === 0) this.filters.work_types = [this.$route.query.work_types];
          else this.filters.work_types.push(this.$route.query.work_types);
          this.filters_used_number = 1;
        } else if (this.$route.query.query) {
          query.query = this.$route.query.query;
          this.filters = query;
        } else if (this.$route.query.content_type) {
          if (Object.keys(this.filters).length === 0) this.filters.content_types = [this.$route.query.content_type];
          else this.filters.content_types.push(this.$route.query.content_type);
          this.filters_used_number = 1;
        }
      } 
    },
    reloadServiceCollectionsNetworks(filters) {
      this.clearFilters(false);
      this.networks.push(filters.networks[0]);
      this.filters = filters;
      this.getProposals();
    },
    removeFixedNavFilters() {
      document.getElementsByClassName('filters-fixed-nav')[0].style.display = 'none';
    },
    clearFilters(update = true) {
      if (Object.keys(this.$route.query).length > 0) {
        this.$router.push({name: 'search_proposal'});
      }
      this.filter_button_selected.selected = false;
      this.filter_button_selected.type_button = '';
      this.filter_button_selected.list_button = []
      this.filters_layout = [];
      this.filters = {};
      this.networks = [];
      this.filters_used_number = 0;
      this.$root.$emit('update_search_query', '');
      this.$root.$emit('update_color_button_selected', this.filter_button_selected);
      if (update) this.getProposals();
    },
    filterAction(filters, filters_used_number, filters_layout) {
      this.open_modal = false;
      this.update_modal = !this.update_modal;
      this.filters_used_number = filters_used_number;
      this.filters_layout = filters_layout;
      this.filters = filters;
      this.getProposals();
    },

    getProposals() {
      this.loader_results = true;
      const query = {...this.filters}; 
      query.networks = this.networks
      query.query = this.$route.query.query ? this.$route.query.query : undefined;
      proposals_services.searchProposals(query).then((response) => {
        this.proposals = response.results;
        this.changFilters = !this.changFilters
        setTimeout(() => {
          this.loader_results = false;
          this.loaded = true;
        }, 500);
      });      
    },
    isActiveClass(name, value, filter = false) {
      return this[name].find((item) => item === value) !== undefined ? filter ? 'active-button-filters' : 'active-button-network' : '';
    },
    addNetworkFilter(net) {
      this.networks.find((network) => network === net) === undefined ? this.networks.push(net) : this.networks = this.networks.filter((item) => item !== net);
      this.getProposals();
    },
  },
}
</script>

<style>
.color-card-custom {
  background-image: linear-gradient(rgb(145, 70, 255), rgb(250, 30, 210), rgb(234, 112, 120));
}
</style>
<style scoped>
.aspect-ratio {
  aspect-ratio: 1 / 1;
}
.grid-search-proposal {
  display: grid;
  grid-template-columns: repeat(5, minmax(100px, 1fr));;
  grid-gap: 2rem;
  grid-auto-flow: dense;
}
.min-height-500 {
  min-height: 500px;
}
.empty-state-container-proposals {
  display: flex;
  justify-content: center;
  align-content: center;
  flex-wrap: wrap;
  text-align: center;
}
.max-width-1400 {
  max-width: 1440px;
  margin: 0px auto;
}
.filters-fixed-nav {
  background-color: white;
  position: sticky;
  width: 100%;
  left: 0;
  top: 10.5em;
  z-index: 50;
  box-shadow: 0px 10px 5px white;
  /* padding: 0px !important; */
  padding: 1em 0 1em 0;
  animation: filters-fixed 600ms;
}
.container-loader-searcher {
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background-color: rgba(255, 255, 255, 0.8);
  z-index: 1000;  
}
.loader-table-cards {
  display: flex;
  justify-content: center;
}
.spinner-table-searcher {
  margin-top: 25em;
  height: 9em;
  width: 9em;
  color: #acacac;
}
.button-filters {
  border: 1px solid #ced4da !important;
  display: flex;
  align-items: center;
  width: 130px !important;
  /* position: absolute; */
}
.button-trash-icon {
  display: block !important;
  width: 60px !important;
}
.button-filters:hover {
  background-color: #e8e8e8 !important;
}
.badge-number-filters-used {
  background-color: #7367F0;
  width: 20px;
  height: 20px;
  border-radius: 10em;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  position: absolute;
  right: 1em;
}
.outline-secondary-light {
  width: 100px !important;
  height: 41px;
  border: 1px solid #ced4da !important;
  border-radius: 5em;
  color: #6c757d !important;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  padding: 1em 5em;
}
.outline-secondary-light:hover {
  background-color: #e8e8e8 !important;
  transition: all 300ms;
}
.outline-secondary-light > .btn {
  padding: 0.2em !important;
}
.active-button-network, .active-button-network:hover {
  background-color: #d5ebe4 !important;
  color: #267e5e !important;
}
.active-button-filters {
  background-color: #d5ebe4 !important;
  color: #267e5e !important;
}
.card-nav-search {
  display: flex !important; 
}
.component-collection-network {
  width: 660px !important;
}
.container-results-proposals {
  max-width: 1440px;
}
.horizontal >>> .v-hl-btn svg {
  border-radius: 0;
  margin: 0;
  padding: 8px;
  height: 100%;
  box-shadow: none;
  background: none;
}

.horizontal >>> .v-hl-btn-prev {
  background: linear-gradient(to left, #ffffff00 0, white 66%, white);
  padding-right: 24px;
}

.horizontal >>> .v-hl-btn-next {
  background: linear-gradient(to right, #ffffff00 0, white 66%, white);
  padding-left: 24px;
}

.horizontal >>> .v-hl-btn {
  top: 0;
  bottom: 0;
  transform: translateX(0);
}
.container-search-filters {
  width: 550px;
}
.container-search-filters .input-search-proposal { 
  border-radius: 1.357rem;
  padding-left: 40px;
  width: 100%;
  height: 41px;
}
.float-suggestions-search {
  width: 100%;
  height: auto;
  background-color: white;
  border-radius: 1em;
  padding: 1em;
  position: absolute;
  top: 4em;
  z-index: 100;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}
.badge-float-suggestions {
  background-color: #f1f1f1 ;
  color: #444;
  cursor: pointer;
}
.img-network-badge {
  width: 18px;
  height: 18px;
}
.container-search-filters > .icon-search {
  position: absolute;
  color: #6c757d;
  z-index: 8;
  font-size: 25px;
  top: 0.3em;
  left: 0.3em;
  cursor: pointer;
}
.p-07 {
  padding: 0.7em;
}
@media(max-width: 1200px) {
  .grid-search-proposal {
    grid-template-columns: repeat(4, minmax(100px, 1fr));;
  }
  .filters-fixed-nav {
    top: 5em;
  }
}
@media(max-width: 1000px) {
  .grid-search-proposal {
    grid-template-columns: repeat(3, minmax(100px, 1fr));;
  }
}
@media(max-width: 700px) {
  .grid-search-proposal {
    grid-template-columns: repeat(2, minmax(100px, 1fr));;
  }
}
@media(max-width: 450px) {
  .grid-search-proposal {
    grid-template-columns: repeat(1, 1fr);;
  }
}
@media(min-width: 1200px) {
  .col-25 {
    max-width: 20% !important;
  }
}
@keyframes filters-fixed {
  0% {
    transform: translateY(-200px);
    opacity: 0;
  }
  100% {
    transform: translateY(0px);
    opacity: 1;
  }
}
</style>